<script setup lang="ts">
import { ClientApiError, Product } from '@shopware-pwa/types';


import {
    getMainImageUrl,
    getTranslatedProperty,
    getProductRoute,
} from '@shopware-pwa/helpers-next';

const props = defineProps<{ product: Product }>();

const { pushSuccess, pushError } = useNotifications();
const { search } = useProductSearch();
const { t } = useI18n();

const { data: productResponse } = useAsyncData(
    'cmsProduct' + props.product?.id,
    async () => {
        const productResponse = await search(props.product?.id, {
            withCmsAssociations: true,
        });
        return productResponse;
    }
);

// const { product, configurator } = useProduct(
//   // productResponse.value?.product,
//   // productResponse.value?.configurator,
// );
/* 
const product = props.product; */

/* 
const colors = ""; */

/* console.log(colors); */


const colors = computed(
    () =>
        productResponse?.value?.configurator
            .find((option) => option.displayType === 'color')
            ?.options?.map((color) => color.colorHexCode) || []
);


const productOrPromoRoute = computed(() => {
    if (
        props.product.markAsTopseller &&
        props.product.customFields?.custom_headless_link_different
    ) {
        return props.product.customFields?.custom_headless_alternativ_link;
    } else {
        return getProductRoute(props.product);
    }
});

const { product: productRef } = toRefs(props);
const { addToWishlist, removeFromWishlist, isInWishlist } =
    useProductWishlist(productRef);

const toggleWishlistProduct = async () => {
    if (!isInWishlist.value) {
        try {
            await addToWishlist();
            return pushSuccess(
                t(`product.messages.addedToWishlist`, {
                    p: props.product?.translated?.name,
                })
            );
        } catch (error) {
            const e = error as ClientApiError;
            const reason = e?.messages?.[0]?.detail
                ? `Reason: ${e?.messages?.[0]?.detail}`
                : '';
            return pushError(
                `${props.product?.translated?.name} konnte nicht favorisiert werden.\n${reason}`,
                {
                    timeout: 5000,
                }
            );
        }
    }
    removeFromWishlist();
};

</script>

<template>
    <article
        v-if="product && productOrPromoRoute"
        class="the-product-card flex flex-col gap-5 relative"
        :class="{ relative: product?.markAsTopseller }"
    >
        <div class="product-cover-wrapper relative">
            <RouterLink
                :to="productOrPromoRoute"
                class="overflow-hidden relative card-img-cover bg-[#f8f8f8]"
                :class="{
                    'has-secondary-image':
                        !product.markAsTopseller &&
                        productResponse?.product.media?.length > 1,
                    'aspect-square': !product.markAsTopseller,
                }"
                :aria-label="'link to ' + getTranslatedProperty(product, 'name')"
            >
                <NuxtPicture format="webp" 
                    loading="lazy"
                    :class="{
                        'aspect-square object-center main-image':
                            !product.markAsTopseller,
                    }"
                    class="w-full object-cover aspect-3/4"
                    :src="getMainImageUrl(product)"
                    :alt="getTranslatedProperty(product, 'name')"
                   
                />
                <!-- Secondary image, do not use like this, it's slow af!! -->
                <NuxtPicture format="webp" 
                    loading="lazy"
                    v-if="
                        !product.markAsTopseller &&
                        productResponse?.product.media?.length > 1
                    "
                    class="secondary-image aspect-square object-cover absolute top-0"
                    :src="
                        productResponse?.product.media[0].media.url ===
                        getMainImageUrl(product)
                            ? productResponse?.product.media[1].media.url
                            : productResponse?.product.media[0].media.url
                    "
                   
                />
                <!-- Custom Tag -->
                <NuxtPicture format="webp" 
                    loading="lazy"
                    v-if="
                        product.translated?.customFields
                            ?.custom_headless_tag_label
                    "
                    :src="`/images/tags/${product.translated?.customFields?.custom_headless_tag_label}.svg`"
                    :alt="
                        product.translated?.customFields
                            ?.custom_headless_tag_label as string
                    "
                    class="absolute left-4 top-4 w-25"
                />
            </RouterLink>
            <button
                aria-label="Add to wishlist"
                type="button"
                class="absolute bg-transparent top-0 right-0 p-4 product-card-wishlist-button"
                data-testid="product-box-toggle-wishlist-button"
                @click="toggleWishlistProduct"
            >
                <client-only>
                    <div
                        v-if="isInWishlist"
                        class="h-7 w-7 i-carbon-favorite-filled c-red-500"
                        data-testid="product-box-wishlist-icon-in"
                        title="Aus Wunschliste entfernen"
                    ></div>
                    <div
                        v-else
                        class="h-7 w-7 i-carbon-favorite"
                        data-testid="product-box-wishlist-icon-not-in"
                        title="Zur Wunschliste"
                    ></div>
                    <template #placeholder>
                        <div class="h-7 w-7 i-carbon-favorite"></div>
                    </template>
                </client-only>
            </button>
        </div>
        <h2
            v-if="product.markAsTopseller"
            class="text-white font-bold absolute left-3 bottom-3 w-2/3"
        >
            {{ getTranslatedProperty(product, 'name') }}
        </h2>
        <div class="product-infos px-4 flex flex-col gap-4" v-else>
            <div class="flex justify-between items-center">
                <RouterLink :to="productOrPromoRoute"  :aria-label="'link to ' + getTranslatedProperty(product, 'name')">
                    <p class="font-bold text-2xl">
                        {{ getTranslatedProperty(product, 'name') }}
                    </p>
                </RouterLink>
                <ProductPrice
                    is-overview
                    class="!text-base"
                    :product="product"
                />
            </div>
            <p
                class="activities-label text-grey text-sm"
                v-if="
                    product.translated?.customFields[
                        'migration_supernatural-merinocom_product_attr15'
                    ]
                "
            >
                <!-- {{ dummyTextVariant() }} -->
                {{
                    product.translated?.customFields[
                        'migration_supernatural-merinocom_product_attr15'
                    ]
                }}
            </p>
            <div class="placeholder-colors" v-if="colors.length <= 0"></div>
            <div class="flex gap-2 items-center" v-else>
                <template v-for="(colorVariant, idx) in colors">
                    <span
                        v-if="idx < 4"
                        class="w-4 h4 rounded-full"
                        :style="{ backgroundColor: colorVariant }"
                    ></span>
                </template>
                <span v-if="colors.length > 4" class="text-grey pt-1">
                    + {{ colors.length - 4 }}
                </span>
            </div>
        </div>
    </article>
    <article v-else class="the-product-card grid grid-flow-row gap-5">
        Loading...
    </article>
</template>
<style lang="scss">
.product-cover-wrapper {
    .product-card-wishlist-button {
        display: none;
    }

    @media screen and (min-width: 1024px) {
        &:hover {
            .product-card-wishlist-button {
                z-index: 2;
                display: block;
            }
        }
    }
}
.card-img-cover {
    display: flex;

    img {
        width: 100%;
    }
    
    &.has-secondary-image {
        .main-image {
            opacity: 1;
        }
        .secondary-image {
            opacity: 0;
            display: none;
            width: 100%;

            @media screen and (max-width: 1024px) {
                display: none !important;
            }
        }

        @media screen and (min-width: 1024px) {
            &:hover {
                .main-image {
                    opacity: 1;
                }
                .secondary-image {
                    z-index: 2;
                    opacity: 1;
                    display: block;
                }
            }
        }
    }
}


</style>
